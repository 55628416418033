import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { CardWrapper } from "../../../styles/style";

export default function AllUpdate() {
  return (
    <CardWrapper>
      <div>
        <Box
          sx={{
            width: "100%",
            position: "relative",
            overflow: { xs: "auto", sm: "initial" },
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              display: "block",
              width: "1px",
              bgcolor: "warning.300",
              left: "500px",
              top: "-24px",
              bottom: "-24px",
              "&::before": {
                top: "4px",

                display: "block",
                position: "absolute",
                right: "0.5rem",
                color: "text.tertiary",
                fontSize: "sm",
                fontWeight: "lg",
              },
              "&::after": {
                top: "4px",

                display: "block",
                position: "absolute",
                left: "0.5rem",
                color: "text.tertiary",
                fontSize: "sm",
                fontWeight: "lg",
              },
            }}
          />
          <Card
            orientation="horizontal"
            sx={{
              width: "100%",
              flexWrap: "wrap",
              [`& > *`]: {
                "--stack-point": "500px",
                minWidth:
                  "clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)",
              },
              // make the card resizable for demo
              overflow: "auto",
              resize: "horizontal",
            }}
          >
            <AspectRatio flex ratio="1" maxHeight={100} sx={{ minWidth: 100 }}>
              <img
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
                srcSet="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286&dpr=2 2x"
                loading="lazy"
                alt=""
              />
            </AspectRatio>
            <CardContent>
              <Typography fontSize="xl" fontWeight="lg">
                Resume Title
              </Typography>
              <div style={{ display: "flex", gap: "10px" }}>
                <Typography
                  level="body-sm"
                  fontWeight="sm"
                  textColor="text.tertiary"
                >
                  Full Name
                </Typography>
                <Typography
                  level="body-sm"
                  fontWeight="sm"
                  textColor="text.tertiary"
                >
                  Age
                </Typography>
                <Typography
                  level="body-sm"
                  fontWeight="sm"
                  textColor="text.tertiary"
                >
                  Gender
                </Typography>
              </div>
              <Sheet
                sx={{
                  bgcolor: "background.level1",
                  borderRadius: "sm",
                  p: 1.5,
                  my: 1.5,
                  display: "flex",
                  gap: 2,
                  "& > div": { flex: 1 },
                }}
              >
                <div>
                  <Typography sx={{ fontSize: "md", fontWeight: "md" }}>
                    Job Category
                  </Typography>
                  <Typography
                    sx={{ fontSize: "md", fontWeight: "md", marginTop: 1 }}
                  >
                    Expected Salary
                  </Typography>
                </div>
                <div>
                  <Typography sx={{ fontSize: "md", fontWeight: "md" }}>
                    Industry
                  </Typography>
                  <Typography
                    sx={{ fontSize: "md", fontWeight: "md", marginTop: 1 }}
                  >
                    Working Experience
                  </Typography>
                </div>
                <div>
                  <Typography sx={{ fontSize: "md", fontWeight: "md" }}>
                    Current Visa
                  </Typography>
                  <Typography
                    sx={{ fontSize: "md", fontWeight: "md", marginTop: 1 }}
                  >
                    Nationality
                  </Typography>
                </div>
              </Sheet>
            </CardContent>
          </Card>
        </Box>
      </div>
    </CardWrapper>
  );
}
