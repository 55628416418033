import React, { useState } from "react";
import axios from "axios";

// Define the structure for each message
interface IMessage {
  role: "user" | "assistant";
  content: string;
}

function Chat() {
  const [message, setMessage] = useState<string>("");
  const [responses, setResponses] = useState<IMessage[]>([]);

  const sendMessage = async () => {
    if (!message.trim()) return; // Prevent sending empty messages
    try {
      const response = await axios.post<{
        choices: [{ message: { content: string } }];
      }>("http://localhost:3001/chat", {
        messages: [{ role: "user", content: message }],
      });

      // Update the responses array with both the user's message and the assistant's response
      setResponses((currentResponses) => [
        ...currentResponses,
        { role: "user", content: message },
        {
          role: "assistant",
          content: response.data.choices[0].message.content,
        },
      ]);
      setMessage(""); // Clear the input after sending
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  return (
    <div>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={(e) => e.key === "Enter" && sendMessage()}
      />
      <button onClick={sendMessage}>Send</button>
      <div>
        {responses.map((msg, index) => (
          <p key={index}>
            <strong>{msg.role}:</strong> {msg.content}
          </p>
        ))}
      </div>
    </div>
  );
}

export default Chat;
