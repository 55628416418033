/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Table from "@mui/joy/Table";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import { Visa, VisaApiResponse, formatDate } from "../../../types/data";
import { baseURL } from "../../../../config";
import { Link } from "react-router-dom";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function OrderTable() {
  const [order, setOrder] = React.useState<Order>("desc");
  const [open, setOpen] = React.useState(false);

  const [visaData, setVisaData] = React.useState<Visa[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const [paginationData, setPaginationData] = React.useState<any>({
    after_filtering_count: 0,
    current_page: 1,
    next_page: null,
    page_count: 0,
  });
  const [page, setPage] = React.useState(paginationData.current_page);

  React.useEffect(() => {
    fetchDocuments(page);
    // fetchAdmins();
  }, [page]);
  const handlePageChange = async (newPage: number) => {
    await fetchDocuments(newPage);
    setPage(newPage);
  };

  const fetchDocuments = async (page: number) => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(baseURL + "/visa-news/all?per_page=20", {
        method: "GET",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: VisaApiResponse = await response.json();
      setVisaData(data.data);
      setPaginationData({
        after_filtering_count: data.after_filtering_count,
        current_page: data.current_page,
        next_page: data.next_page,
        page_count: data.page_count,
      });
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  // React.useEffect(() => {
  //   fetchDocuments();
  // }, []);
  const renderPageNumbers = () => {
    const pages = [];
    const totalNumbers = 6;
    const sideNumbers = 1;

    if (paginationData.page_count <= totalNumbers) {
      for (let i = 1; i <= paginationData.page_count; i++) {
        pages.push(i);
      }
    } else {
      let startPage = Math.max(2, page - sideNumbers);
      let endPage = Math.min(paginationData.page_count - 1, page + sideNumbers);

      if (page <= sideNumbers + 2) {
        startPage = 2;
        endPage = totalNumbers - 1;
      } else if (page >= paginationData.page_count - sideNumbers - 1) {
        startPage = paginationData.page_count - totalNumbers + 2;
        endPage = paginationData.page_count - 1;
      }

      pages.push(1);
      if (startPage > 2) pages.push("...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < paginationData.page_count - 1) pages.push("...");
      pages.push(paginationData.page_count);
    }

    return pages;
  };
  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "flex", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel sx={{ fontSize: 14 }}>Search for order</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
          />
        </FormControl>
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "flex", sm: "flex" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{ width: 48, textAlign: "center", padding: "12px 6px" }}
              >
                번호
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                뉴스제목
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                등록일자
              </th>
              <th
                style={{ padding: "12px 6px", textAlign: "center", width: 68 }}
              >
                등록자
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                공개여부
              </th>
              <th
                style={{ width: 68, padding: "12px 6px", textAlign: "center" }}
              >
                상세보기
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                수정 / 삭제
              </th>
            </tr>
          </thead>
          <tbody>
            {visaData.map((visa, index) => (
              <tr key={visa._id}>
                <td style={{ textAlign: "center", width: 120 }}>
                  <Typography level="body-xs">{index + 1}</Typography>
                </td>

                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {visa.title || "no title"}
                  </Typography>
                </td>

                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {formatDate(visa.created_at)}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {visa.registrant || "register"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center", width: 60 }}>
                  <Typography level="body-xs">
                    {visa.status || "no status"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center", width: 120 }}>
                  <Link
                    to={`/visa-news/${visa._id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography level="body-xs" color="primary">
                      View
                    </Typography>
                  </Link>
                </td>

                <td style={{ textAlign: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                      justifyContent: "center ",
                    }}
                  >
                    <Typography level="body-xs" color="primary">
                      Download
                    </Typography>
                    <Typography level="body-xs" color="danger">
                      Delete
                    </Typography>
                    <Modal
                      aria-labelledby="modal-title"
                      aria-describedby="modal-desc"
                      open={open}
                      onClose={() => setOpen(false)}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Sheet
                        variant="outlined"
                        sx={{
                          maxWidth: 600,
                          borderRadius: "md",
                          p: 3,
                          boxShadow: "lg",
                        }}
                      >
                        <ModalClose variant="plain" sx={{ m: 1 }} />
                        <Typography
                          component="h2"
                          id="modal-title"
                          level="h4"
                          textColor="inherit"
                          fontWeight="lg"
                          mb={1}
                        >
                          Do you want to download file?
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            gap: 3,
                          }}
                        >
                          <Button variant="soft">Yes</Button>
                          <Button variant="outlined" color="danger">
                            No
                          </Button>
                        </Box>
                      </Sheet>
                    </Modal>

                    {/* <Link
                      level="body-xs"
                      component="button"
                      onClick={handleViewPage}
                    >
                      Edit
                    </Link> */}
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          onClick={() => handlePageChange(page - 1)}
          disabled={page <= 1}
        >
          Previous
        </Button>
        <Box sx={{ flex: 1 }} />
        {renderPageNumbers().map((pageNumber, index) => (
          <IconButton
            key={index}
            size="sm"
            variant={pageNumber === page ? "solid" : "outlined"}
            color="neutral"
            onClick={() =>
              typeof pageNumber === "number" && handlePageChange(pageNumber)
            }
            disabled={pageNumber === "..."}
          >
            {pageNumber}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          onClick={() => handlePageChange(page + 1)}
          disabled={page >= paginationData.page_count}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}
