/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Table from "@mui/joy/Table";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import Sheet from "@mui/joy/Sheet";
import { baseURL } from "../../../../../config";
import {
  JobPosting,
  JobPostingApiResponse,
} from "../../../../types/jobPosting";
import { formatDate } from "../../../../types/data";
import { Link } from "react-router-dom";
import { ViewDownBtn } from "../../../../styles/style";

export default function AllPosting() {
  const [paginationData, setPaginationData] = React.useState<any>({
    after_filtering_count: 0,
    current_page: 1,
    next_page: null,
    page_count: 0,
  });
  const [page, setPage] = React.useState(paginationData.current_page);
  const [jobPostingData, setJobPostingData] = React.useState<JobPosting[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    fetchDocuments(page);
    // fetchAdmins();
  }, [page]);

  const handlePageChange = async (newPage: number) => {
    await fetchDocuments(newPage);
    setPage(newPage);
  };

  const fetchDocuments = async (page: number) => {
    const token = localStorage.getItem("token");
    let allData: JobPosting[] = [];
    try {
      const response = await fetch(
        baseURL + "/job/all?page=" + page.toString(),
        {
          method: "GET",
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: JobPostingApiResponse = await response.json();
      setJobPostingData(data.data);
      // setFilteredData(data.data);
      setPaginationData({
        after_filtering_count: data.after_filtering_count,
        current_page: data.current_page,
        next_page: data.next_page,
        page_count: data.page_count,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    const totalNumbers = 6;
    const sideNumbers = 1;

    if (paginationData.page_count <= totalNumbers) {
      for (let i = 1; i <= paginationData.page_count; i++) {
        pages.push(i);
      }
    } else {
      let startPage = Math.max(2, page - sideNumbers);
      let endPage = Math.min(paginationData.page_count - 1, page + sideNumbers);

      if (page <= sideNumbers + 2) {
        startPage = 2;
        endPage = totalNumbers - 1;
      } else if (page >= paginationData.page_count - sideNumbers - 1) {
        startPage = paginationData.page_count - totalNumbers + 2;
        endPage = paginationData.page_count - 1;
      }

      pages.push(1);
      if (startPage > 2) pages.push("...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < paginationData.page_count - 1) pages.push("...");
      pages.push(paginationData.page_count);
    }

    return pages;
  };
  return (
    <React.Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "flex", sm: "flex" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
          marginTop: 3,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{ width: 48, textAlign: "center", padding: "12px 6px" }}
              >
                번호
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                채용공고명
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                게시일자
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                회사명
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                지원자
              </th>
              {/* <th style={{ padding: "12px 6px", textAlign: "center" }}>직종</th> */}
              <th style={{ padding: "12px 6px", textAlign: "center" }}>금액</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                마감일
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                상세보기
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={8} style={{ textAlign: "center" }}>
                  <p>Loading...</p>
                </td>
              </tr>
            ) : (
              jobPostingData.map((value, index) => {
                return (
                  <tr key={value._id}>
                    <td style={{ textAlign: "center", width: 120 }}>
                      <Typography level="body-xs">
                        {" "}
                        {Number(index + 1) < 10
                          ? (page - 1).toString() + Number(index + 1)
                          : page + "0"}
                      </Typography>
                    </td>

                    <td style={{ textAlign: "center" }}>
                      <Typography level="body-xs">
                        {value.job_title || "---"}
                      </Typography>
                    </td>

                    <td style={{ textAlign: "center" }}>
                      <Typography level="body-xs">
                        {formatDate(value.created_at) || "---"}
                      </Typography>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Typography level="body-xs">
                        {/* Need to update */}
                        {value.company.slice(0, 5) || "---"}
                      </Typography>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Typography level="body-xs">data</Typography>
                    </td>

                    <td style={{ textAlign: "center" }}>
                      <Typography level="body-xs">
                        {value.salary_min || "---"} ~{" "}
                        {value.salary_max || "---"}
                      </Typography>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Typography level="body-xs">
                        {value.deadline || "---"}
                      </Typography>
                    </td>

                    <td style={{ textAlign: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          alignItems: "center",
                          justifyContent: "center ",
                        }}
                      >
                        <Link
                          to={`/job-posting-management/${value._id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <ViewDownBtn>view</ViewDownBtn>
                        </Link>
                      </Box>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          onClick={() => handlePageChange(page - 1)}
          disabled={page <= 1}
        >
          Previous
        </Button>
        <Box sx={{ flex: 1 }} />
        {renderPageNumbers().map((pageNumber, index) => (
          <IconButton
            key={index}
            size="sm"
            variant={pageNumber === page ? "solid" : "outlined"}
            color="neutral"
            onClick={() =>
              typeof pageNumber === "number" && handlePageChange(pageNumber)
            }
            disabled={pageNumber === "..."}
          >
            {pageNumber}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          onClick={() => handlePageChange(page + 1)}
          disabled={page >= paginationData.page_count}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}
