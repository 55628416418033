export const formatDate = (isoDate: string): string => {
  const date = new Date(isoDate);
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);

  return `${hours}:${minutes} || ${year}.${month}.${day}`;
};

export const formatMonthDate = (isoDate: string): string => {
  const date = new Date(isoDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");

  return `${month}.${day}`;
};

export const formatBirthday = (isoDate: string): string => {
  const date = new Date(isoDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear().toString();

  return `${year}.${month}.${day}`;
};

export interface AdminList {
  _id: string;
  email: string;
  isApproved: boolean;
  name: string;
  phone: string;
  role: string;
  position: string[];
}

export interface AdminListApiResponse {
  data: AdminList[];
  after_filtering_count: number;
  current_page: number;
  next_page: null | number;
  page_count: number;
  success: boolean;
}

export interface Document {
  _id: string;
  file_name: string;
  file_path: string;
  created_at: string;
  updated_at: string;
}

export interface ApiResponse {
  data: Document[];
  after_filtering_count: number;
  current_page: number;
  next_page: null | number;
  page_count: number;
  success: boolean;
}

export interface Visa {
  _id: string;
  images: string[];
  created_at: string;
  updated_at: string;
  desc: string;
  registrant: string;
  status: string;
  title: string;
}

export interface VisaApiResponse {
  data: Visa[];
  after_filtering_count: number;
  current_page: number;
  next_page: null | number;
  page_count: number;
  success: boolean;
}

// export interface Company {
//   _id: string;
//   name: string;
//   address: string;
//   registration_number: string;
//   country: string;
//   region: string;
//   industry: string;
//   representative_name: string;
//   email: string;
//   contact: string;
//   role: string;
//   status: string;
//   created_at: string;
//   updated_at: string;
//   phone: string;
//   workplace: string;
//   place_of_study?: string;
//   education_type: string;
//   visa: string;
//   reason: {
//     autocad: string;
//     can_tig_welding: string;
//     education_date: string;
//     education_salary_type: string;
//     education_time: string;
//     education_type: string;
//     reason: string;
//     type_of_welding: string;
//     message?:string;
//   };
// }

export interface Company {
  _id: string;
  name: string;
  address: string;
  registration_number: string;
  country: string;
  region: string;
  industry: string;
  representative_name: string;
  email: string;
  contact: string;
  role: string;
  status: string;
  created_at: string;
  updated_at: string;
  phone: string;
  workplace: string;
  place_of_study?: string;
  education_type: string;
  visa: string;
  manager_name: string;
  report_message: string;

  reason: {
    autocad: string;
    can_tig_welding: string;
    education_date: string;
    education_salary_type: string;
    education_time: string;
    education_type: string;
    reason: string;
    type_of_welding: string;
    message?: string;
  };
}

export interface UsersById {
  _id: string;
  username: string;
  name: string;
  birthday: string;
  phone: string;
  email: string;
  role: string;
  status: string;
  contact: string;
  created_at: string;
  updated_at: string;
  gender: string;
  country: string;
  visa?: string;
  position: string;
  workplace?: string;
  reason?: {
    message?: string;
  };
}

export interface CompanyApiResponse {
  data: Company[];
  after_filtering_count: number;
  current_page: number;
  next_page: null | number;
  page_count: number;
  success: boolean;
}

export interface Users {
  _id: string;
  username: string;
  name: string;
  birthday: string;
  phone: string;
  email: string;
  role: string;
  status: string;
  contact: string;
  created_at: string;
  updated_at: string;
  gender: string;
  country: string;
  phone_number: string;
}

export interface UserData {
  _id: string;
  username: string;
  name: string;
  birthday: string;
  phone: string;
  email: string;
  role: string;
  status: string;
  contact: string;
  created_at: string;
  updated_at: string;
  gender: string;
  country: string;
  visa: string;
  position: string;
  address: string;
  workplace: string;
  place_of_study: string;
  language: string;
  report_message: string;
  data: Users[];
  reason: {
    illegal_income_amount: string;
    violation_fine_amount: string;
    is_illegal_income_amount: boolean;
    is_violation_fine_amount: boolean;
    autocad: string;
    can_tig_welding: string;
    education_date: string;
    education_salary_type: string;
    education_time: string;
    education_type: string;
    reason: string;
    type_of_welding: string;
    message?: string;
  };
  resume?: string;
}

export interface UsersApiResponse {
  data: Users[];
  after_filtering_count: number;
  current_page: number;
  next_page: null | number;
  page_count: number;
  success: boolean;
}

export interface ReportUserData {
  _id: string;
  name: string;
  phone: string;
  country: string;
  email: string;
  workplace: string;
  place_of_study: string;
  created_at: string;
  updated_at: string;
}
