import { useEffect, useState } from "react";
import { baseURL } from "../../../../config";
import { Visa, formatDate } from "../../../types/data";
import { useNavigate, useParams } from "react-router-dom";

import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const VisaDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState<Visa | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseURL}/visa-news/get/${id}`, {
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setData(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  console.log(data);

  let navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: { xs: 2, md: 6 },
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: { xs: 2, sm: 2, md: 3 },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon />}
          sx={{ pl: 0 }}
        >
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home"
          >
            <HomeRoundedIcon />
          </Link>

          <Typography color="primary" fontWeight={500} fontSize={12}>
            Reported Data
          </Typography>
          <Typography color="success" fontWeight={500} fontSize={12}>
            {data?.registrant}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box>
        <Stack
          spacing={4}
          sx={{
            display: "flex",
            maxWidth: "800px",
            mx: "auto",
            px: { xs: 2, md: 6 },
            py: { xs: 2, md: 3 },
          }}
        >
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">
                {/* {data.company.company_name} */}
              </Typography>
              <Typography level="body-sm">Visa News</Typography>
            </Box>
            <Divider />

            <Stack direction="row" spacing={2}>
              <FormControl sx={{ flexGrow: 1 }}>
                <FormLabel>Title</FormLabel>
                <Input
                  size="sm"
                  placeholder="tech skills"
                  value={data?.title}
                />
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <FormControl sx={{ flexGrow: 1 }}>
                <FormLabel>Registrant</FormLabel>
                <Input
                  size="sm"
                  placeholder="tech skills"
                  value={data?.registrant}
                />
              </FormControl>
              <FormControl sx={{ flexGrow: 1 }}>
                <FormLabel>Uploaded Date</FormLabel>
                <Input
                  size="sm"
                  placeholder="tech skills"
                  value={data?.created_at ? formatDate(data.created_at) : ""}
                />
              </FormControl>
            </Stack>
          </Card>
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">Description</Typography>
              <Stack spacing={2} sx={{ my: 1 }}>
                <Textarea
                  size="sm"
                  minRows={4}
                  sx={{ mt: 1.5 }}
                  value={data?.desc}
                />
              </Stack>
            </Box>
          </Card>
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">Photos</Typography>
              <Stack spacing={2} sx={{ my: 1 }}>
                {data?.images?.map((image, index) => (
                  <img key={index} src={image} alt={`Image ${index + 1}`} />
                ))}
              </Stack>
            </Box>
          </Card>
          <CardOverflow
            sx={{
              borderTop: "1px solid",
              borderColor: "divider",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <CardActions sx={{ alignSelf: "flex-end", pt: 2, gap: 2 }}>
              <Button
                size="lg"
                variant="outlined"
                color="neutral"
                onClick={handleBack}
              >
                Back
              </Button>
            </CardActions>
          </CardOverflow>
        </Stack>
      </Box>
    </Box>
  );
};

export default VisaDetail;
