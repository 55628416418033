import MemberListTable from "../../constant/tables/membersList/memberListTable";

const GeneralMemberList = () => {
  return (
    <div style={{ width: "100%" }}>
      <MemberListTable />
    </div>
  );
};

export default GeneralMemberList;
