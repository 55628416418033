import ControlTable from "../constant/tables/control/controlListTable";

const ControlComponent = () => {
  return (
    <div>
      <ControlTable />
    </div>
  );
};

export default ControlComponent;
