import AIchat from "../../constant/AIchat";

const ChattingComponent = () => {
  return (
    <div>
      <AIchat />
    </div>
  );
};

export default ChattingComponent;
