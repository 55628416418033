/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";

import Table from "@mui/joy/Table";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";

import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import Sheet from "@mui/joy/Sheet";
import { Link, useNavigate } from "react-router-dom";
import { Company, CompanyApiResponse, formatDate } from "../../../types/data";
import { baseURL } from "../../../../config";
import { ViewDownBtn } from "../../../styles/style";

type Order = "asc" | "desc";

export default function OrderTable() {
  const navigate = useNavigate();
  const [companyData, setCompanyData] = React.useState<Company[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const fetchDocuments = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        baseURL + "/company/all?page=1&per_page=10",
        {
          method: "GET",
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: CompanyApiResponse = await response.json();
      setCompanyData(data.data);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "flex", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel sx={{ fontSize: 14 }}>Search for documentation</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
          />
        </FormControl>
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "flex", sm: "flex" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: 48,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              >
                번호
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>계정</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                가입일자
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>이름</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                회사명
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                이메일
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                가입경로
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                회원상태
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                상세보기
              </th>
            </tr>
          </thead>
          <tbody>
            {companyData.map((company, index) => (
              <tr key={company._id}>
                <td style={{ textAlign: "center", width: 120 }}>
                  <Typography level="body-xs"> {index + 1}</Typography>
                </td>

                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.email || "no email"}
                  </Typography>
                </td>

                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {formatDate(company.created_at) || "no date"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.representative_name || "no name"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.name || "no name"}
                  </Typography>
                </td>

                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.email || "no email"}
                  </Typography>
                </td>

                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">KakaoTalk</Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {" "}
                    {company.status || "no email"}
                  </Typography>
                </td>

                <td style={{ textAlign: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                      justifyContent: "center ",
                    }}
                  >
                    <Link
                      to={`/corporate-member-list/${company._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <ViewDownBtn>view</ViewDownBtn>
                    </Link>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
        >
          Previous
        </Button>

        <Box sx={{ flex: 1 }} />
        {["1", "2", "3", "…", "8", "9", "10"].map((page) => (
          <IconButton
            key={page}
            size="sm"
            variant={Number(page) ? "outlined" : "plain"}
            color="neutral"
          >
            {page}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}
