import DocumentationTable from "../../constant/tables/documentation/documentationTable";

const Documentation = () => {
  return (
    <>
      <DocumentationTable />
    </>
  );
};

export default Documentation;
