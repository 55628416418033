import * as React from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import Stack from "@mui/joy/Stack";
import Add from "@mui/icons-material/Add";
import LinearProgress from "@mui/joy/LinearProgress";
import Typography from "@mui/joy/Typography";
import Key from "@mui/icons-material/Key";
import { green } from "@mui/material/colors";
import { baseURL } from "../../../../config";
import { toast } from "react-toastify";

export default function AddNewMember() {
  const [open, setOpen] = React.useState(false);
  const minLength = 12;
  const timer = React.useRef<NodeJS.Timeout | null>(null);

  const [formData, setFormData] = React.useState({
    name: "",
    username: "",
    phone: "",
    whether_to_use: "Y",
    email: "",
    password: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const token = localStorage.getItem("token");
    event.preventDefault();

    const response = await fetch(baseURL + "/admin/sign-up-admin", {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      const data = await response.json();
      toast.success("New Member added successfully");
      setOpen(false);
    } else {
      console.error("Error:", response.statusText);
      alert(response.statusText);
    }
  };

  React.useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const getPasswordStrengthText = (length: number) => {
    if (length < 3) return "Very weak";
    if (length >= 3 && length < 6) return "Weak";
    if (length >= 6 && length < 10) return "Strong";
    if (length >= 10) return "Very strong";
    return "";
  };

  return (
    <React.Fragment>
      <Button
        variant="solid"
        color="primary"
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
      >
        New member
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            New member
          </DialogTitle>

          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>이름</FormLabel>
                <Input
                  autoFocus
                  required
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>전화번호</FormLabel>
                <Input
                  type="number"
                  required
                  name="phone"
                  placeholder="010-****-****"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl>
                <FormLabel>직</FormLabel>
                <Input
                  autoFocus
                  required
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>이메일</FormLabel>
                <Input
                  type="email"
                  autoFocus
                  required
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>비밀번호</FormLabel>
                <Stack
                  spacing={0.5}
                  sx={{
                    "--hue": Math.min(formData.password.length * 10, 120),
                  }}
                >
                  <Input
                    type="password"
                    placeholder="Type in here…"
                    startDecorator={<Key />}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <LinearProgress
                    determinate
                    size="sm"
                    value={Math.min(
                      (formData.password.length * 100) / minLength,
                      100
                    )}
                    sx={{
                      bgcolor: "background.level3",
                      color: "hsl(var(--hue) 80% 40%)",
                    }}
                  />
                  <Typography
                    level="body-xs"
                    sx={{
                      alignSelf: "flex-end",
                      color: "hsl(var(--hue) 80% 30%)",
                    }}
                  >
                    {getPasswordStrengthText(formData.password.length)}
                  </Typography>
                </Stack>
              </FormControl>
              <Button type="submit">Submit</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
