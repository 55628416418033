/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";

import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";

import ModalDialog from "@mui/joy/ModalDialog";

import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";

import Sheet from "@mui/joy/Sheet";
import { Link, useNavigate } from "react-router-dom";
import {
  AdminList,
  Users,
  UsersApiResponse,
  formatBirthday,
  formatDate,
} from "../../../types/data";
import { ViewDownBtn } from "../../../styles/style";
import { baseURL } from "../../../../config";

export default function OrderTable() {
  const [open, setOpen] = React.useState(false);

  const [usersData, setUsersData] = React.useState<Users[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const [adminData, setAdminData] = React.useState<Users[]>([]);
  const [paginationData, setPaginationData] = React.useState<any>({
    after_filtering_count: 0,
    current_page: 1,
    next_page: null,
    page_count: 0,
  });

  const [page, setPage] = React.useState(paginationData.current_page);

  React.useEffect(() => {
    fetchDocuments(page);
    fetchAdmins();
  }, [page]);

  const handlePageChange = async (newPage: number) => {
    await fetchDocuments(newPage);
    setPage(newPage);
  };

  const fetchDocuments = async (page: number) => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        baseURL + "/user/all?page=" + page.toString(),
        {
          method: "GET",
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: UsersApiResponse = await response.json();
      setUsersData(data.data);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const fetchAdmins = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(baseURL + "/admin/all?page=1&per_page=10", {
        method: "GET",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: UsersApiResponse = await response.json();
      setAdminData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("data", usersData);
  const renderPageNumbers = () => {
    const pages = [];
    const totalNumbers = 6;
    const sideNumbers = 1;

    if (paginationData.page_count <= totalNumbers) {
      for (let i = 1; i <= paginationData.page_count; i++) {
        pages.push(i);
      }
    } else {
      let startPage = Math.max(2, page - sideNumbers);
      let endPage = Math.min(paginationData.page_count - 1, page + sideNumbers);

      if (page <= sideNumbers + 2) {
        startPage = 2;
        endPage = totalNumbers - 1;
      } else if (page >= paginationData.page_count - sideNumbers - 1) {
        startPage = paginationData.page_count - totalNumbers + 2;
        endPage = paginationData.page_count - 1;
      }

      pages.push(1);
      if (startPage > 2) pages.push("...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < paginationData.page_count - 1) pages.push("...");
      pages.push(paginationData.page_count);
    }

    return pages;
  };

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel sx={{ fontSize: 14 }}>성별</FormLabel>
        <Select
          size="sm"
          placeholder="Filter by status"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          <Option value="male">남자</Option>
          <Option value="female">여자</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel sx={{ fontSize: 14 }}>Country</FormLabel>
        <Select size="sm" placeholder="All">
          <Option value="all">All</Option>
          <Option value="uzbekistan">Uzbekistan</Option>
          <Option value="china">China</Option>
          <Option value="vietnam">Vietnam</Option>
        </Select>
      </FormControl>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: "flex", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel sx={{ fontSize: 14 }}>Search for order</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "flex", sm: "flex" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{ width: 48, textAlign: "center", padding: "12px 6px" }}
              >
                번호
              </th>
              <th style={{ padding: "12px 6px" }}>계정/이메일</th>
              <th style={{ padding: "12px 6px" }}>가입일자</th>
              <th style={{ width: 68, padding: "12px 6px" }}>성별</th>
              <th style={{ padding: "12px 6px" }}>국적</th>
              <th style={{ padding: "12px 6px" }}>전화번호</th>
              {/* <th style={{ padding: "12px 6px" }}>가입 경로</th> */}
              <th style={{ padding: "12px 6px" }}> 회원상태</th>
              <th style={{ padding: "12px 6px" }}> 이력서</th>
            </tr>
          </thead>
          <tbody>
            {usersData.map((user, index) => (
              <tr key={user._id}>
                <td style={{ textAlign: "center", width: 120 }}>
                  <Typography level="body-xs"> {index + 1}</Typography>
                </td>
                <td>
                  <Typography level="body-xs">
                    {user.email || "no email"}
                  </Typography>
                </td>
                <td>
                  <Typography level="body-xs">
                    {formatDate(user.created_at) || "---"}
                  </Typography>
                </td>
                <td>
                  <Typography level="body-xs">
                    {user.gender || "---"}
                  </Typography>
                </td>
                <td>
                  <Typography level="body-xs">
                    {user.country || "---"}
                  </Typography>
                </td>
                <td>
                  <Typography level="body-xs">
                    {/* {formatBirthday(user.birthday || "---")} */}
                    {user.phone_number || "---"}
                  </Typography>
                </td>
                {/* <td>
                  <Typography level="body-xs">homepage</Typography>
                </td> */}
                <td>
                  <Typography level="body-xs">
                    {user.status || "no status"}
                  </Typography>
                </td>

                <td>
                  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <ViewDownBtn onClick={() => setOpen(true)}>
                      Download
                    </ViewDownBtn>
                    <Modal
                      aria-labelledby="modal-title"
                      aria-describedby="modal-desc"
                      open={open}
                      onClose={() => setOpen(false)}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Sheet
                        variant="outlined"
                        sx={{
                          maxWidth: 600,
                          borderRadius: "md",
                          p: 3,
                          boxShadow: "lg",
                        }}
                      >
                        <ModalClose variant="plain" sx={{ m: 1 }} />
                        <Typography
                          component="h2"
                          id="modal-title"
                          level="h4"
                          textColor="inherit"
                          fontWeight="lg"
                          mb={1}
                        >
                          Do you want to download file?
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            gap: 3,
                          }}
                        >
                          <Button variant="soft">Yes</Button>
                          <Button variant="outlined" color="danger">
                            No
                          </Button>
                        </Box>
                      </Sheet>
                    </Modal>
                    <Link to={`${user._id}`} style={{ textDecoration: "none" }}>
                      <ViewDownBtn>View</ViewDownBtn>
                    </Link>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          onClick={() => handlePageChange(page - 1)}
          disabled={page <= 1}
        >
          Previous
        </Button>
        <Box sx={{ flex: 1 }} />
        {renderPageNumbers().map((pageNumber, index) => (
          <IconButton
            key={index}
            size="sm"
            variant={pageNumber === page ? "solid" : "outlined"}
            color="neutral"
            onClick={() =>
              typeof pageNumber === "number" && handlePageChange(pageNumber)
            }
            disabled={pageNumber === "..."}
          >
            {pageNumber}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          onClick={() => handlePageChange(page + 1)}
          disabled={page >= paginationData.page_count}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}
