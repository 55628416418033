import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";
import ToDayUpdate from "./toDayUpdate";
import AllUpdate from "./allUpdate";

export default function TabsPricingExample() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Tabs
        variant="outlined"
        aria-label="Pricing plan"
        defaultValue={0}
        sx={{
          // width: 343,
          // width: "50vw",
          width: "80vw",

          borderRadius: "lg",
          boxShadow: "sm",
          overflow: "auto",
        }}
      >
        <TabList
          disableUnderline
          tabFlex={1}
          sx={{
            [`& .${tabClasses.root}`]: {
              fontSize: "sm",
              fontWeight: "lg",
              [`&[aria-selected="true"]`]: {
                color: "primary.500",
                bgcolor: "background.surface",
              },
              [`&.${tabClasses.focusVisible}`]: {
                outlineOffset: "-4px",
              },
            },
          }}
        >
          <Tab disableIndicator variant="soft">
            오늘 업데이트된 이력서
          </Tab>
          {/* <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
          Pro
        </Tab> */}
          <Tab disableIndicator variant="soft">
            전체 이력서
          </Tab>
        </TabList>
        <TabPanel value={0}>
          <ToDayUpdate />
        </TabPanel>
        <TabPanel value={1}>
          <AllUpdate />
        </TabPanel>
      </Tabs>
    </div>
  );
}
