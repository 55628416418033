import ReportTable from "../../constant/tables/report/reportTable";

const ReportDataComponent = () => {
  return (
    <div>
      <ReportTable />
    </div>
  );
};

export default ReportDataComponent;
