import { useParams } from "react-router-dom";

const DetailOfferComponent = () => {
  let { id } = useParams();
  return (
    <div>
      <p>{id}</p>
      <p style={{ fontSize: 30 }}>Soon....</p>
    </div>
  );
};

export default DetailOfferComponent;
