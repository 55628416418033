import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { baseURL } from "../../../../config";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import Stack from "@mui/joy/Stack";
import Select, { selectClasses } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import {
  AdminList,
  AdminListApiResponse,
  UserData,
  formatBirthday,
  formatDate,
} from "../../../types/data";
import Snackbar from "@mui/joy/Snackbar";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import { KeyboardArrowDown } from "@mui/icons-material";
import { DialogActions, Modal, ModalClose, Sheet } from "@mui/joy";
import axios from "axios";
import { toast } from "react-toastify";

const DetailData = () => {
  const { id } = useParams();
  const [data, setData] = useState<UserData | null>(null);
  const [open, setOpen] = React.useState(false);
  // const [selectedID, setSelectedID] = useState("");
  const [reportMessage, setReportMessage] = React.useState<string>("");
  const [managerName, setManagerName] = React.useState<string>("");
  const [adminData, setAdminData] = React.useState<AdminList[]>([]);
  const [openModal, setModalOpen] = React.useState<boolean>(false);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseURL}/user-data/get/${id}`, {
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setData(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  // console.log("data", data);

  console.log("adminData", adminData);

  const handleBack = () => {
    navigate(-1);
  };

  const handleDownload = () => {
    try {
      if (data && data.resume) {
        const resumeUrl: string = data.resume;
        const userName: string = data.name || "user";
        const link = document.createElement("a");
        link.href = resumeUrl;
        link.download = `${userName.trim()}-resume.docx`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Resume URL is not available for download.");
        setOpen(true);
      }
    } catch (error) {
      console.error("Download CV error:", error);
    }
  };

  useEffect(() => {
    const fetchAdmins = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          baseURL + "/admin/all?page=1&per_page=10",
          {
            method: "GET",
            headers: {
              accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data: AdminListApiResponse = await response.json();
        setAdminData(data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAdmins();
  }, []);
  const updateReport = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        baseURL + `/user-data/update-report/${id}`,
        {
          manager_name: managerName,
          report_message: reportMessage,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.success) {
        // fetchDocuments(page); // Refresh data on current page
        setOpen(false);
        setManagerName("");
        setReportMessage("");
        // setSelectedID("");
        toast.success("Report data is uploaded!");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error?.msg);
    }
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  const shouldRenderTextarea = data?.reason?.reason && data.reason.message;
  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: { xs: 2, md: 6 },
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: { xs: 2, sm: 2, md: 3 },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        //   minWidth: 0,

        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon />}
          sx={{ pl: 0 }}
        >
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home"
          >
            <HomeRoundedIcon />
          </Link>

          <Typography color="primary" fontWeight={500} fontSize={12}>
            Reported Data
          </Typography>
          <Typography color="success" fontWeight={500} fontSize={12}>
            {data.name}
          </Typography>
        </Breadcrumbs>
      </Box>
      {/* <h1>Detail Data</h1>
      <p>ID: {id}</p>
      <p>Data: {JSON.stringify(data)}</p> */}
      <Box>
        <Stack
          spacing={4}
          sx={{
            display: "flex",
            maxWidth: "800px",
            mx: "auto",
            px: { xs: 2, md: 6 },
            py: { xs: 2, md: 3 },
          }}
        >
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">Personal info</Typography>
              <Typography level="body-sm">
                Customize how your profile information will apper to the
                networks.
              </Typography>
            </Box>
            <Divider />
            <Stack
              direction="row"
              spacing={3}
              sx={{ display: { xs: "none", md: "flex" }, my: 1 }}
            >
              <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <Stack spacing={1}>
                  <FormLabel>Name</FormLabel>
                  <Box
                    sx={{
                      display: { sm: "flex-column", md: "flex-row" },
                      gap: 2,
                    }}
                  >
                    <Input
                      size="sm"
                      placeholder="First name"
                      value={data.name}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormControl>
                    <FormLabel>Visa</FormLabel>
                    <Input size="sm" placeholder="-" value={data.visa} />
                  </FormControl>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      size="sm"
                      placeholder="Phone Number"
                      value={data.phone}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormControl>
                    <FormLabel>Gender</FormLabel>
                    <Input size="sm" value={data.gender} />
                  </FormControl>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <FormLabel>Birthday</FormLabel>
                    <Input
                      size="sm"
                      placeholder="Phone Number"
                      value={formatBirthday(data.birthday) || "no date"}
                    />
                  </FormControl>
                </Stack>

                <div>
                  <FormControl sx={{ display: { sm: "contents" } }}>
                    <FormLabel>Country</FormLabel>
                    <Select size="sm" defaultValue="1">
                      <Option value="1">{data.country}</Option>
                    </Select>
                  </FormControl>
                </div>
                <Stack direction="row" spacing={2}>
                  <FormControl>
                    <FormLabel>position</FormLabel>
                    <Input size="sm" value={data.position} placeholder="-" />
                  </FormControl>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <FormLabel>Company</FormLabel>
                    <Input size="sm" placeholder="-" value={data.workplace} />
                  </FormControl>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <FormLabel>University</FormLabel>
                    <Input
                      size="sm"
                      placeholder="-"
                      value={data.place_of_study}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormControl>
                    <FormLabel>Language</FormLabel>
                    <Input
                      size="sm"
                      placeholder="Language"
                      value={data.language}
                    />
                  </FormControl>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <FormLabel>Applied Date</FormLabel>
                    <Input
                      size="sm"
                      placeholder="Address"
                      value={formatDate(data.created_at)}
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
          </Card>
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">Manager</Typography>
              <FormControl sx={{ mt: 1.5 }}>
                <Select
                  placeholder="담당자 선택"
                  indicator={<KeyboardArrowDown />}
                  onChange={(e, value) => {
                    setManagerName(value as string);
                  }}
                  value={managerName}
                  sx={{
                    [`& .${selectClasses.indicator}`]: {
                      transition: "0.2s",
                      [`&.${selectClasses.expanded}`]: {
                        transform: "rotate(-180deg)",
                      },
                    },
                  }}
                >
                  <Option value="">Not manager</Option>
                  {adminData.map((item) => (
                    <Option key={item._id} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
                <Stack spacing={2} sx={{ my: 1 }}>
                  <Typography level="title-md">내용</Typography>
                  <Textarea
                    onChange={(e) => {
                      setReportMessage(e.target.value);
                    }}
                    value={reportMessage}
                    minRows={4}
                    sx={{ margin: "10px 0" }}
                  />
                </Stack>
                <DialogActions>
                  <Button
                    variant="solid"
                    color="success"
                    onClick={updateReport}
                  >
                    Done
                  </Button>
                </DialogActions>
                <DialogActions>
                  <Button
                    variant="solid"
                    color="primary"
                    onClick={() => setModalOpen(true)}
                    // onClick={updateReport}
                  >
                    Send to "To Do List"
                  </Button>
                </DialogActions>
              </FormControl>
            </Box>
            <Divider />
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">Reason</Typography>
              <FormControl sx={{ flexGrow: 1, mt: 2 }}>
                <Input
                  size="sm"
                  placeholder="reason"
                  value={
                    data.reason.reason
                      ? data.reason.education_type
                        ? `${data.reason.reason} || ${data.reason.education_type}`
                        : data.reason.reason
                      : ""
                  }
                />
              </FormControl>
            </Box>
            <Divider />
            <Stack spacing={2} sx={{ my: 1 }}>
              {/* <EditorToolbar /> */}
              <Textarea
                size="sm"
                minRows={4}
                sx={{ mt: 1.5 }}
                value={data.report_message}
              />
            </Stack>
            <Divider />

            <div>
              {!data ? (
                <div>Loading...</div>
              ) : (
                <>
                  {shouldRenderTextarea &&
                    ["Work", "Other"].includes(data.reason.reason) && (
                      <Stack spacing={2} sx={{ my: 1 }}>
                        {/* <EditorToolbar /> */}
                        <Textarea
                          size="sm"
                          minRows={4}
                          sx={{ mt: 1.5 }}
                          value={data.reason.message || "-----"}
                        />
                      </Stack>
                    )}
                </>
              )}
            </div>
          </Card>

          <>
            <Snackbar
              variant="soft"
              color="danger"
              open={open}
              onClose={() => setOpen(false)}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
              endDecorator={
                <Button
                  onClick={() => setOpen(false)}
                  size="sm"
                  variant="plain"
                  color="danger"
                >
                  Cancel
                </Button>
              }
            >
              {data.name} didn't upload CV
            </Snackbar>
          </>
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">
                Portfolio projects (Resume)
              </Typography>
              <Typography level="body-sm">
                Share a few snippets of your work.
              </Typography>
            </Box>
            <Divider />
            <Button
              size="lg"
              variant="solid"
              color="success"
              onClick={handleDownload}
            >
              Download CV
            </Button>
          </Card>
          <CardOverflow
            sx={{
              borderTop: "1px solid",
              borderColor: "divider",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <CardActions sx={{ alignSelf: "flex-end", pt: 2, gap: 2 }}>
              <Button
                size="lg"
                variant="outlined"
                color="neutral"
                onClick={handleBack}
              >
                Back
              </Button>
              {/* <Button size="lg" variant="solid">
                Save
              </Button> */}
            </CardActions>
          </CardOverflow>
        </Stack>
      </Box>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openModal}
        onClose={() => setModalOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
            height: 150,
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            sx={{ fontWeight: "lg", mb: 1, mt: 2 }}
          >
            This data will be sent to the ‘To Do List’ table
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button variant="solid" sx={{ flex: 1 }}>
              YES
            </Button>
            <Button
              variant="soft"
              color="danger"
              sx={{ flex: 1 }}
              onClick={() => setModalOpen(false)}
            >
              NO
            </Button>
          </Box>
        </Sheet>
      </Modal>
    </Box>
  );
};

export default DetailData;
