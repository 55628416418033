import AutocadTable from "../../../constant/tables/education/autocad/reportTable";

const AutocadComponent = () => {
  return (
    <div>
      <AutocadTable />
    </div>
  );
};

export default AutocadComponent;
