import WeldingTable from "../../../constant/tables/education/welding/weldingTable";

const WeldingComponent = () => {
  return (
    <>
      <WeldingTable />
    </>
  );
};

export default WeldingComponent;
