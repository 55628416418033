import CorporateMemberListTable from "../../constant/tables/corporateManagement/memberListTable";

const CorporateMemberList = () => {
  return (
    <>
      <CorporateMemberListTable />
    </>
  );
};

export default CorporateMemberList;
