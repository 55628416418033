import VisaListTable from "../../constant/tables/visa/visaListTable";

const VisaNews = () => {
  return (
    <div>
      <VisaListTable />
    </div>
  );
};

export default VisaNews;
