/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";

import Table from "@mui/joy/Table";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";

import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";

import { AdminList, AdminListApiResponse } from "../../../types/data";
import { DialogTitle, ModalDialog, Stack } from "@mui/joy";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { Chip } from "@mui/joy";
import { baseURL } from "../../../../config";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function OrderTable() {
  const [open, setOpen] = React.useState(false);
  const [id, setID] = React.useState("");
  const [openSetting, setOpenSetting] = React.useState<boolean>(false);
  const [adminDatas, setAdminData] = React.useState<AdminList[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  let navigate = useNavigate();

  const fetchDocuments = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(baseURL + "/admin/all?page=1&per_page=10", {
        method: "GET",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: AdminListApiResponse = await response.json();
      setAdminData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserById = async (id: string) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${baseURL}/admin/get/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.success) {
        const user = response?.data?.data;
        setSelectedOptions(user.position);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleSelectChange = (event: any, value: any) => {
    setSelectedOptions(value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${baseURL}/admin/set-position/${id}`,
        { position: selectedOptions },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.success) {
        toast.success("Positions updated successfully");
        setOpenSetting(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [deleteId, setDeleteId] = useState("");

  const handleDelete = async (event: any) => {
    event.preventDefault();
    console.log("Deleting admin with ID:", deleteId); // Check if deleteId is correct
    const token = localStorage.getItem("token");
    if (!deleteId) {
      console.error("Delete ID is not set");
      return;
    }
    try {
      const response = await fetch(`${baseURL}/admin/delete/${deleteId}`, {
        method: "DELETE",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        toast.success("Admin deleted successfully");
        setTimeout(() => {
          navigate("/data/report-data");
        }, 2000);
        // alert("Admin deleted successfully");
      } else {
        console.error(
          "Failed to delete admin",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error deleting admin:", error);
    }
  };
  React.useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "flex", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel sx={{ fontSize: 14 }}>Search for order</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
          />
        </FormControl>
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "flex", sm: "flex" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{ width: 48, textAlign: "center", padding: "12px 6px" }}
              >
                번호
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>이름</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                전화번호
              </th>
              <th
                style={{ padding: "12px 6px", textAlign: "center", width: 68 }}
              >
                이메일
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>직</th>
              <th
                style={{ width: 68, padding: "12px 6px", textAlign: "center" }}
              >
                active
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>삭제</th>
            </tr>
          </thead>
          <tbody>
            {adminDatas.map((admindata, index) => (
              <tr key={admindata._id}>
                <td style={{ textAlign: "center", width: 120 }}>
                  <Typography level="body-xs"> {index + 1}</Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {admindata.name || "-"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {admindata.phone || "-"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {admindata.email || "-"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center", width: 60 }}>
                  <Typography level="body-xs">
                    {admindata.role || "-"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center", width: 120 }}>
                  <Typography level="body-xs">
                    {admindata.isApproved || "-"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                      justifyContent: "center ",
                    }}
                  >
                    <Link
                      level="body-xs"
                      component="button"
                      onClick={() => {
                        setOpenSetting(true);
                        setID(admindata._id);
                        getUserById(admindata._id);
                      }}
                    >
                      {admindata.role === "ADMIN" && "Setting"}
                    </Link>
                    <Modal
                      open={openSetting}
                      onClose={() => setOpenSetting(false)}
                    >
                      <ModalDialog>
                        <DialogTitle
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Setting Position
                        </DialogTitle>

                        <form onSubmit={handleSubmit}>
                          <Stack spacing={2}>
                            <FormControl>
                              <FormLabel>Data Offer</FormLabel>
                              <Select
                                multiple
                                onChange={handleSelectChange}
                                value={selectedOptions}
                                renderValue={(selected) => (
                                  <Box sx={{ display: "flex", gap: "0.25rem" }}>
                                    {selected.map((selectedOption) => (
                                      <Chip variant="soft" color="primary">
                                        {selectedOption.label}
                                      </Chip>
                                    ))}
                                  </Box>
                                )}
                                sx={{
                                  minWidth: "15rem",
                                }}
                                slotProps={{
                                  listbox: {
                                    sx: {
                                      width: "100%",
                                    },
                                  },
                                }}
                              >
                                <Option value="general_member_table">
                                  일반회원 관리
                                </Option>
                                <Option value="company_table">
                                  기업회원 관리
                                </Option>
                                <Option value="chatting_table">고객상담</Option>
                                <Option value="documentation_table">
                                  기업 서식 자료실
                                </Option>
                                <Option value="visa_news_table">
                                  비자 뉴스 관리
                                </Option>
                                <Option value="job_offer">Job Offer</Option>

                                <Option value="all_data">
                                  데이터/All Data
                                </Option>
                                <Option value="todo_table">데이터/To Do</Option>
                                <Option value="proccess_table">
                                  데이터/Proccess
                                </Option>
                                <Option value="done_table">데이터/Done</Option>
                                <Option value="welding_table">
                                  학원/Welding
                                </Option>
                                <Option value="autocad_table">
                                  학원/Autocad
                                </Option>
                                <Option value="IT_table">학원/IT </Option>
                                <Option value="sns_link_table">
                                  Link Generate
                                </Option>
                              </Select>
                            </FormControl>
                            <Button type="submit">Submit</Button>
                          </Stack>
                        </form>
                      </ModalDialog>
                    </Modal>

                    <Link
                      level="body-xs"
                      component="button"
                      onClick={() => {
                        setOpen(true);
                        setDeleteId(admindata._id); // Ensure the correct ID is set for deletion
                      }}
                      sx={{ color: "darkred" }}
                    >
                      {admindata.role === "ADMIN" && "Delete"}
                    </Link>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
        >
          Previous
        </Button>

        <Box sx={{ flex: 1 }} />
        {["1", "2", "3", "…", "8", "9", "10"].map((page) => (
          <IconButton
            key={page}
            size="sm"
            variant={Number(page) ? "outlined" : "plain"}
            color="neutral"
          >
            {page}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
        >
          Next
        </Button>
      </Box>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 600,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Do you want to delete admin?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: 3,
            }}
          >
            <Button variant="soft" onClick={handleDelete}>
              Yes
            </Button>
            <Button
              variant="outlined"
              color="danger"
              onClick={() => setOpen(false)}
            >
              No
            </Button>
          </Box>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}
