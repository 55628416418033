import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";
import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";

import Sheet from "@mui/joy/Sheet";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { Container } from "../../styles/style";
import { FormControl, FormLabel, Input, Option, Select } from "@mui/joy";
import NewNotice from "../../constant/tables/corporateManagement/posting/newNotice";
import HiringComponent from "../../constant/tables/corporateManagement/posting/Hiring";
import RecruitmentEnded from "../../constant/tables/corporateManagement/posting/recruitmentEnded";
import AllPosting from "../../constant/tables/corporateManagement/posting/allPosting";

export default function JobPostingTabs() {
  return (
    <Container style={{ justifyContent: "left" }}>
      <Box sx={{ display: "flex" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon />}
          sx={{ pl: 0 }}
        >
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home"
          >
            <HomeRoundedIcon />
          </Link>
          <Typography fontWeight={500} fontSize={12}>
            기업회원 관리
          </Typography>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            채용공고 관리
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2" component="h1">
          채용공고 관리
        </Typography>

        <Button
          color="primary"
          startDecorator={<DownloadRoundedIcon />}
          size="sm"
        >
          Download XLSX
        </Button>
      </Box>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "flex", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel sx={{ fontSize: 14 }}>Search for documentation</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
          />
        </FormControl>
        <FormControl size="sm">
          <FormLabel sx={{ fontSize: 14 }}>company</FormLabel>
          <Select
            size="sm"
            placeholder="Filter by company"
            slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
          >
            <Option value="male">company 1</Option>
            <Option value="female">company 2</Option>
          </Select>
        </FormControl>
      </Box>
      <Tabs
        variant="outlined"
        aria-label="Pricing plan"
        defaultValue={0}
        sx={{
          borderRadius: "lg",
          boxShadow: "sm",
          overflow: "auto",
        }}
      >
        <TabList
          disableUnderline
          tabFlex={1}
          sx={{
            [`& .${tabClasses.root}`]: {
              fontSize: "sm",
              fontWeight: "lg",
              [`&[aria-selected="true"]`]: {
                color: "primary.500",
                bgcolor: "background.surface",
              },
              [`&.${tabClasses.focusVisible}`]: {
                outlineOffset: "-4px",
              },
            },
          }}
        >
          <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
            신규공고
          </Tab>
          <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
            채용중
          </Tab>
          <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
            채용종료
          </Tab>
          <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
            전체
          </Tab>
        </TabList>
        <TabPanel value={0}>
          <NewNotice />
        </TabPanel>
        <TabPanel value={1}>
          <HiringComponent />
        </TabPanel>
        <TabPanel value={2}>
          <RecruitmentEnded />
        </TabPanel>
        <TabPanel value={3}>
          <AllPosting />
        </TabPanel>
      </Tabs>
    </Container>
  );
}
