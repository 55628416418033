import ITTable from "../../../constant/tables/education/IT/reportTable";

const ITComponent = () => {
  return (
    <div>
      <ITTable />
    </div>
  );
};

export default ITComponent;
