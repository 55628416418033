import React, { useState, ChangeEvent } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { Container, DisplayFlexWrap, TextContainer } from "../../styles/style";
import Textarea from "@mui/joy/Textarea";
import SvgIcon from "@mui/joy/SvgIcon";
import { styled } from "@mui/joy";
// import axios from "axios";
import Alert from "@mui/joy/Alert";
import AspectRatio from "@mui/joy/AspectRatio";
import IconButton from "@mui/joy/IconButton";
import LinearProgress from "@mui/joy/LinearProgress";

import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import axios from "axios";
import { baseURL } from "../../../config";
// import { baseURL } from "../../../config";

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const AddNews: React.FC = () => {
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [files, setFiles] = useState<FileList | null>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFiles(event.target.files);
    setIsUploaded(true);
  };

  const uploadFiles = async (files: FileList) => {
    const uploadedFileUrls: string[] = [];

    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append("file", files[i]);

      try {
        const response = await axios.post(baseURL + "/upload/file", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        uploadedFileUrls.push(response.data.file_path);
      } catch (error) {
        console.error("Error uploading file:", error);
        throw new Error("File upload failed");
      }
    }

    return uploadedFileUrls;
  };

  const handleSubmit = async () => {
    if (!files || files.length === 0) {
      alert("At least one file is required.");
      return;
    }

    try {
      const uploadedFileUrls = await uploadFiles(files);

      const newsData = {
        title: title,
        desc: content,
        images: uploadedFileUrls,
      };

      const newsResponse = await axios.post(
        baseURL + "/visa-news/add",
        newsData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setShowAlert(true);
      setTimeout(() => {
        navigate("/visa-news");
      }, 2000); // Redirect after 2 seconds
    } catch (error) {
      console.error("Error submitting document:", error);
    }
  };

  return (
    <Container>
      <Box
        component="main"
        className="MainComponent"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100dvh",
          gap: 1,
        }}
      >
        {showAlert && (
          <Alert
            size="lg"
            color="success"
            variant="solid"
            invertedColors
            startDecorator={
              <AspectRatio
                variant="solid"
                ratio="1"
                sx={{
                  minWidth: 40,
                  borderRadius: "50%",
                  boxShadow: "0 2px 12px 0 rgb(0 0 0/0.2)",
                }}
              >
                <div>
                  <Check />
                </div>
              </AspectRatio>
            }
            endDecorator={
              <IconButton
                variant="plain"
                sx={{
                  "--IconButton-size": "32px",
                  transform: "translate(0.5rem, -0.5rem)",
                }}
              >
                <Close />
              </IconButton>
            }
            sx={{
              alignItems: "flex-start",
              overflow: "hidden",
              position: "absolute",
              bottom: 20,
              right: 20,
              zIndex: 20,
            }}
          >
            <div>
              <Typography level="title-lg">Success</Typography>
              <Typography level="body-sm">
                Success is walking from failure to failure with no loss of
                enthusiasm.
              </Typography>
            </div>
            <LinearProgress
              variant="solid"
              color="success"
              value={100}
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: 0,
              }}
            />
          </Alert>
        )}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon />}
            sx={{ pl: 0 }}
          >
            <Link
              underline="none"
              color="neutral"
              href="#some-link"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Link>
            <Typography fontWeight={500} fontSize={12}>
              비자 뉴스 관리
            </Typography>
            <Typography color="primary" fontWeight={500} fontSize={12}>
              뉴스 등록
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Typography level="h2" component="h1">
            뉴스 등록
          </Typography>
          <Button
            color="primary"
            startDecorator={<AddIcon />}
            size="md"
            onClick={handleSubmit}
          >
            등록
          </Button>
        </Box>
        <FormControl>
          <FormLabel sx={{ fontSize: 15 }}>뉴스 제목 *</FormLabel>
          <Input
            placeholder="뉴스 제목"
            sx={{ padding: "8px" }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>
        <FormLabel sx={{ fontSize: 15, marginTop: 2 }}>내용 *</FormLabel>
        <Textarea
          placeholder="내용 입력"
          minRows={10}
          sx={{
            "--Textarea-focusedInset": "var(--any, )",
            "--Textarea-focusedThickness": "0.25rem",
            "--Textarea-focusedHighlight": "rgba(13,110,253,.25)",
            "&::before": {
              transition: "box-shadow .15s ease-in-out",
            },
            "&:focus-within": {
              borderColor: "#86b7fe",
            },
          }}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <FormLabel sx={{ fontSize: 15, marginTop: 2 }}>Images *</FormLabel>
        <TextContainer>
          <h1>At least 1 image is required for a news.</h1>
          <p>We recommend to upload 2 or more images.</p>
        </TextContainer>
        <DisplayFlexWrap style={{ marginTop: 6 }}>
          <Button
            component="label"
            role={undefined}
            tabIndex={-1}
            variant="outlined"
            color="neutral"
            style={{
              flex: 1,
              height: 180,
              color: isUploaded ? "green" : "inherit",
            }}
            startDecorator={
              <SvgIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                  />
                </svg>
              </SvgIcon>
            }
          >
            {isUploaded ? "Uploaded" : "Upload a file"}
            <VisuallyHiddenInput
              type="file"
              multiple
              onChange={handleFileChange}
            />
          </Button>
        </DisplayFlexWrap>
      </Box>
    </Container>
  );
};

export default AddNews;
