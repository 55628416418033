import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { baseURL } from "../../../../config";
import { Button } from "@mui/joy";
import JobOfferTable from "./jobOfferTable";
import {
  DataWrapper,
  UserDataCompany,
  UserDataContainer,
  UserDataCountry,
  UserDataUniversity,
} from "../../../styles/style";

export default function JobOfferComponent() {
  const handleDownload = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        baseURL + "/company-job-offer/download-excel",
        {
          method: "GET",
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();

      const urlObject = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = urlObject;
      const contentDisposition = response.headers.get("content-disposition");
      let fileName = "download.xlsx";
      if (contentDisposition && contentDisposition.includes("filename=")) {
        fileName = contentDisposition
          .split("filename=")[1]
          .replace(/['"]/g, "")
          .trim();
      }
      a.download = fileName;

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  return (
    <>
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: { xs: 2, md: 6 },
          pt: {
            xs: "calc(12px + var(--Header-height))",
            sm: "calc(12px + var(--Header-height))",
            md: 3,
          },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          //   minWidth: 0,
          height: "100dvh",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon />}
            sx={{ pl: 0 }}
          >
            <Link
              underline="none"
              color="neutral"
              href="#some-link"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Link>

            <Typography color="primary" fontWeight={500} fontSize={12}>
              Job Offers
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography level="h2" component="h1" sx={{ flex: 1 }}>
              Job Offers
            </Typography>
            <Button onClick={handleDownload}>Download Excel</Button>
          </Box>
          <DataWrapper>
            <UserDataContainer>
              <h1>Total:</h1>
              <p>20</p>
            </UserDataContainer>

            <UserDataCountry>
              <h1>Country:</h1>
              <p>20</p>
            </UserDataCountry>
            <UserDataCompany>
              <h1>Student:</h1>
              <p>20</p>
            </UserDataCompany>
            <UserDataUniversity>
              <h1>Employee:</h1>
              <p>20</p>
            </UserDataUniversity>
          </DataWrapper>
        </Box>
        <JobOfferTable />
      </Box>
    </>
  );
}
