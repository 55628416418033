import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { baseURL } from "../../../config";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { JobDetailPosting } from "../../types/jobPosting";
import { formatDate } from "../../types/data";

const PostingDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState<JobDetailPosting | null>(null);

  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseURL}/job/get/${id}`, {
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setData(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  if (!data) {
    return <div>Loading...</div>;
  }

  const handleBack = () => {
    navigate(-1);
  };

  const techSkillData = data.company.tech_stack.map((value) => value);
  const requiredSkillData = data.required_skills.map((value) => value);
  const categoriesData = data.categories.map((value) => value);
  const typeEmployment = data.type_of_employment.map((value) => value);
  const benefitData = data.benefit.map((value) => value);

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: { xs: 2, md: 6 },
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: { xs: 2, sm: 2, md: 3 },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon />}
          sx={{ pl: 0 }}
        >
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home"
          >
            <HomeRoundedIcon />
          </Link>

          <Typography color="primary" fontWeight={500} fontSize={12}>
            Reported Data
          </Typography>
          <Typography color="success" fontWeight={500} fontSize={12}>
            {data.company.company_name}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box>
        <Stack
          spacing={4}
          sx={{
            display: "flex",
            maxWidth: "800px",
            mx: "auto",
            px: { xs: 2, md: 6 },
            py: { xs: 2, md: 3 },
          }}
        >
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">
                {data.company.company_name}
              </Typography>
              <Typography level="body-sm">Job Posting</Typography>
            </Box>
            <Divider />
            <Stack
              direction="row"
              spacing={3}
              sx={{ display: { xs: "none", md: "flex" }, my: 1 }}
            >
              <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={2}>
                    <FormControl>
                      <FormLabel>Company Name</FormLabel>
                      <Input
                        size="sm"
                        placeholder="-"
                        value={data.company.company_name || "---"}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Job Title</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Job title"
                        value={data.job_title || "---"}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Industry</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Job title"
                        value={data.company.industry || "---"}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
                <Stack spacing={2} sx={{ flexGrow: 1 }}>
                  <Stack direction="row" spacing={2}>
                    <FormControl>
                      <FormLabel>Phone Number</FormLabel>
                      <Input
                        size="sm"
                        placeholder="-"
                        value={data.company.phone_number || "---"}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Email</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Job title"
                        value={data.company.email || "---"}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Location</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Job title"
                        value={data.company.location || "---"}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
                <Stack spacing={2} sx={{ flexGrow: 1 }}>
                  <Stack direction="row" spacing={2}>
                    <FormControl>
                      <FormLabel>Nationality</FormLabel>
                      <Input
                        size="sm"
                        placeholder="-"
                        value={data.nationality || "---"}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Work Place</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Job title"
                        value={data.working_place || "---"}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Age</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Job title"
                        value={data.age || "---"}
                      />
                    </FormControl>
                  </Stack>
                </Stack>

                <Stack spacing={2} sx={{ flexGrow: 1 }}>
                  <Stack direction="row" spacing={2}>
                    <FormControl>
                      <FormLabel>Employee</FormLabel>
                      <Input
                        size="sm"
                        placeholder="-"
                        value={data.company.employee || "---"}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Work Day</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Job title"
                        value={data.workday || "---"}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Web Site</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Job title"
                        value={data.company.website || "---"}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
                <Stack spacing={2} sx={{ flexGrow: 1 }}>
                  <Stack direction="row" spacing={2}>
                    <FormControl>
                      <FormLabel>Korean Skill</FormLabel>
                      <Input
                        size="sm"
                        placeholder="-"
                        value={data.korean_language_skill || "---"}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Gender</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Job title"
                        value={data.gender || "---"}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Posted Date</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Job title"
                        value={formatDate(data.created_at || "---")}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <FormLabel>Tech Skills</FormLabel>
                    <Input
                      size="sm"
                      placeholder="tech skills"
                      value={techSkillData}
                    />
                  </FormControl>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <FormLabel>Required Skills</FormLabel>
                    <Input
                      size="sm"
                      placeholder="Required Skill Data"
                      value={requiredSkillData}
                    />
                  </FormControl>
                </Stack>
                <Stack spacing={2} sx={{ flexGrow: 1 }}>
                  <Stack direction="row" spacing={2}>
                    <FormControl>
                      <FormLabel>Min Salary</FormLabel>
                      <Input
                        size="sm"
                        placeholder="-"
                        value={data.salary_min || "---"}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Max Salary</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Max Salary"
                        value={data.salary_min || "---"}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Deadline</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Job title"
                        value={data.deadline || "---"}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <FormLabel>Categories</FormLabel>
                    <Input
                      size="sm"
                      placeholder="tech skills"
                      value={categoriesData}
                    />
                  </FormControl>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <FormLabel>Type Employment</FormLabel>
                    <Input
                      size="sm"
                      placeholder="Required Skill Data"
                      value={typeEmployment}
                    />
                  </FormControl>
                </Stack>
                <Stack spacing={2} sx={{ flexGrow: 1 }}>
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Benefits</FormLabel>
                      <Input
                        size="sm"
                        placeholder="-"
                        value={benefitData || "---"}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Card>
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">Description</Typography>
              <Stack spacing={2} sx={{ my: 1 }}>
                <Textarea
                  size="sm"
                  minRows={4}
                  sx={{ mt: 1.5 }}
                  value={data.description}
                />
              </Stack>
            </Box>
          </Card>

          <CardOverflow
            sx={{
              borderTop: "1px solid",
              borderColor: "divider",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <CardActions sx={{ alignSelf: "flex-end", pt: 2, gap: 2 }}>
              <Button
                size="lg"
                variant="outlined"
                color="neutral"
                onClick={handleBack}
              >
                Back
              </Button>
            </CardActions>
          </CardOverflow>
        </Stack>
      </Box>
    </Box>
  );
};

export default PostingDetail;
