import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { Company, CompanyApiResponse, formatDate } from "../../../types/data";
import SearchIcon from "@mui/icons-material/Search";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useState } from "react";
import { baseURL } from "../../../../config";
import { Link } from "react-router-dom";

export default function OrderTable() {
  const [companyData, setCompanyData] = React.useState<Company[]>([]);
  const [filteredData, setFilteredData] = React.useState<Company[]>([]);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [paginationData, setPaginationData] = useState<any>({
    after_filtering_count: 0,
    current_page: 1,
    next_page: null,
    page_count: 0,
  });

  const [page, setPage] = useState(paginationData.current_page);

  React.useEffect(() => {
    fetchDocuments(page);
    //fetchAdmins();
  }, [page]);

  const handlePageChange = async (newPage: number) => {
    await fetchDocuments(newPage);
    setPage(newPage);
  };

  const fetchDocuments = async (page: number) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        baseURL + "/user-data/all?page=" + page.toString(),
        {
          method: "GET",
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: CompanyApiResponse = await response.json();
      setCompanyData(data.data);
      setFilteredData(data.data);
      setPaginationData({
        after_filtering_count: data.after_filtering_count,
        current_page: data.current_page,
        next_page: data.next_page,
        page_count: data.page_count,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchAdmins = async () => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const response = await fetch(baseURL + "/admin/all?page=1&per_page=10", {
  //       method: "GET",
  //       headers: {
  //         accept: "*/*",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const data: AdminListApiResponse = await response.json();
  //     setAdminData(data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const updateReport = async () => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const response = await axios.put(
  //       baseURL + "/user-data/update-report/" + selectedID,
  //       {
  //         manager_name: managerName,
  //         report_message: reportMessage,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (response?.data?.success) {
  //       fetchDocuments(page); // Refresh data on current page
  //       setOpen(false);
  //       setManagerName("");
  //       setReportMessage("");
  //       setSelectedID("");
  //     }
  //   } catch (error: any) {
  //     toast.error(error?.response?.data?.error?.msg);
  //   }
  // };

  // React.useEffect(() => {
  //   fetchDocuments(1);
  // }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
      const filtered = companyData.filter(
        (company) =>
          company.name.toLowerCase().includes(query.toLowerCase()) ||
          company.country.toLowerCase().includes(query.toLowerCase()) ||
          company.phone.toLowerCase().includes(query.toLowerCase()) ||
          company.visa.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(companyData);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    const totalNumbers = 6;
    const sideNumbers = 1;

    if (paginationData.page_count <= totalNumbers) {
      for (let i = 1; i <= paginationData.page_count; i++) {
        pages.push(i);
      }
    } else {
      let startPage = Math.max(2, page - sideNumbers);
      let endPage = Math.min(paginationData.page_count - 1, page + sideNumbers);

      if (page <= sideNumbers + 2) {
        startPage = 2;
        endPage = totalNumbers - 1;
      } else if (page >= paginationData.page_count - sideNumbers - 1) {
        startPage = paginationData.page_count - totalNumbers + 2;
        endPage = paginationData.page_count - 1;
      }

      pages.push(1);
      if (startPage > 2) pages.push("...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < paginationData.page_count - 1) pages.push("...");
      pages.push(paginationData.page_count);
    }

    return pages;
  };
  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "flex", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel sx={{ fontSize: 14 }}>Search for user</FormLabel>
          <Input
            size="sm"
            placeholder="Search by name, country, or phone"
            startDecorator={<SearchIcon />}
            value={searchQuery}
            onChange={handleSearch}
          />
        </FormControl>
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "flex", sm: "flex" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: 48,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              >
                번호
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>이름</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                전화 번호
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>비자</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>국가</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>이유</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                담당자
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>날짜</th>
              <th
                style={{
                  padding: "12px 6px",
                  textAlign: "center",
                }}
              >
                보기
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((company, index) => (
              <tr key={company._id}>
                <td style={{ textAlign: "center", width: 120 }}>
                  <Typography level="body-xs">
                    {Number(index + 1) < 10
                      ? (page - 1).toString() + Number(index + 1)
                      : page + "0"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.name || "no name"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.phone || "no phone"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.visa || "no email"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.country || "no country"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.reason?.message || "-"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.manager_name || "-"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {formatDate(company.created_at) || "no date"}
                  </Typography>
                </td>
                <td
                  style={{
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Link
                      to={`/report-data/${company._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        level="body-xs"
                        color="primary"
                        fontSize={13}
                        sx={{ cursor: "pointer" }}
                      >
                        view
                      </Typography>
                    </Link>
                    {/* <Typography
                      level="body-xs"
                      color="success"
                      fontSize={13}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpen(true);
                        fetchAdmins();
                        setSelectedID(company._id);
                        setManagerName(company.manager_name);
                        setReportMessage(company.report_message);
                      }}
                    >
                      report
                    </Typography> */}
                  </div>{" "}
                  {/* </Link> */}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          onClick={() => handlePageChange(page - 1)}
          disabled={page <= 1}
        >
          Previous
        </Button>
        <Box sx={{ flex: 1 }} />
        {renderPageNumbers().map((pageNumber, index) => (
          <IconButton
            key={index}
            size="sm"
            variant={pageNumber === page ? "solid" : "outlined"}
            color="neutral"
            onClick={() =>
              typeof pageNumber === "number" && handlePageChange(pageNumber)
            }
            disabled={pageNumber === "..."}
          >
            {pageNumber}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          onClick={() => handlePageChange(page + 1)}
          disabled={page >= paginationData.page_count}
        >
          Next
        </Button>
      </Box>

      {/* <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          sx={{ width: "450px" }}
        >
          <DialogTitle>
            <WarningRoundedIcon />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography
              level="body-xs"
              fontSize={18}
              sx={{ cursor: "pointer", fontWeight: 700, marginTop: 2 }}
            >
              Manager
            </Typography>
            <Select
              placeholder="담당자 선택"
              indicator={<KeyboardArrowDown />}
              onChange={(e, value) => {
                setManagerName(value as string);
              }}
              value={managerName}
              sx={{
                [`& .${selectClasses.indicator}`]: {
                  transition: "0.2s",
                  [`&.${selectClasses.expanded}`]: {
                    transform: "rotate(-180deg)",
                  },
                },
              }}
            >
              <Option value="">Not manager</Option>
              {adminData.map((item) => (
                <Option key={item._id} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
            <Typography
              level="body-xs"
              fontSize={18}
              sx={{ cursor: "pointer", fontWeight: 700, marginTop: 2 }}
            >
              내용
            </Typography>
            <Textarea
              onChange={(e) => {
                setReportMessage(e.target.value);
              }}
              value={reportMessage}
              minRows={8}
              sx={{ margin: "10px 0" }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="success" onClick={updateReport}>
              Done
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => {
                setOpen(false);
                setManagerName("");
                setReportMessage("");
                setSelectedID("");
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal> */}
    </React.Fragment>
  );
}
