import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  padding: 2%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 85vw;

  @media only screen and (max-width: 1200px) {
    width: 80vw;
  }
  /* width: 85vw; */
  @media only screen and (max-width: 900px) {
    padding-top: 80px;
    width: 100vw;
  }
`;

export const CardWrapper = styled.div`
  cursor: pointer;
  :hover {
    background-color: #f0f4f8;
    border-radius: 5px;
  }
`;

export const TextContainer = styled.div`
  background-color: #f9fcff;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid lightgray;
  h1 {
    font-weight: 600;
    font-size: 16px;
  }
  p {
    font-weight: 400;
    font-size: 14;
  }
`;

export const DisplayFlexWrap = styled.div`
  display: flex;
  gap: 50px;

  @media only screen and (max-width: 900px) {
    display: grid;
    gap: 20px;
  }
`;

export const NavLink = styled(Link)`
  text-decoration: none;
`;

export const ViewDownBtn = styled.div`
  cursor: pointer;
  color: #007bff;
  font-size: 12px;
  font-weight: 500;
`;

export const DataWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex: 4;
  width: 100%;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const UserDataContainer = styled.div`
  flex: 1;
  /* height: 100px; */
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  background-color: #afbcff;
  display: flex;
  flex-direction: column;
  padding: 5px;
  /* justify-content: center;
  align-items: center; */

  h1 {
    font-size: 28px;
    font-weight: 600;
    font-family: "Baloo Bhai 2", sans-serif;
    padding-left: 10px;
    opacity: 0.6;
  }
  p {
    text-align: center;
    font-size: 50px;
    font-weight: 600;
    font-family: "Baloo Bhai 2", sans-serif;
  }
  @media only screen and (max-width: 1200px) {
    flex: 1;
    width: 100%;
    padding: 0 10px;
  }
`;

export const UserTigContainer = styled.div`
  flex: 1;
  /* height: 100px; */
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  background-color: #c4dfdd;
  display: flex;
  flex-direction: column;
  padding: 5px;
  h1 {
    font-size: 28px;
    font-weight: 600;
    font-family: "Baloo Bhai 2", sans-serif;
    padding-left: 10px;
    opacity: 0.6;
  }
  p {
    text-align: center;
    font-size: 50px;
    font-weight: 600;
    font-family: "Baloo Bhai 2", sans-serif;
  }
  @media only screen and (max-width: 1200px) {
    flex: 1;
    width: 100%;
    padding: 0 10px;
  }
`;

export const UserDataCountry = styled.div`
  flex: 1;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  background-color: #cde8bf;
  display: flex;
  flex-direction: column;
  padding: 5px;
  h1 {
    font-size: 28px;
    font-weight: 600;
    font-family: "Baloo Bhai 2", sans-serif;
    padding-left: 10px;
    opacity: 0.6;
  }
  p {
    text-align: center;
    font-size: 50px;
    font-weight: 600;
    font-family: "Baloo Bhai 2", sans-serif;
  }
  @media only screen and (max-width: 1200px) {
    flex: 1;
    width: 100%;
    padding: 0 10px;
  }
`;

export const UserDataCompany = styled.div`
  flex: 1;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  background-color: #fecff9;
  display: flex;
  flex-direction: column;
  padding: 5px;
  h1 {
    font-size: 28px;
    font-weight: 600;
    font-family: "Baloo Bhai 2", sans-serif;
    padding-left: 10px;
    opacity: 0.6;
  }
  p {
    text-align: center;
    font-size: 50px;
    font-weight: 600;
    font-family: "Baloo Bhai 2", sans-serif;
  }
  @media only screen and (max-width: 1200px) {
    flex: 1;
    width: 100%;
    padding: 0 10px;
  }
`;

export const UserDataUniversity = styled.div`
  flex: 1;

  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  background-color: #ffeca7;
  display: flex;
  flex-direction: column;
  padding: 5px;
  h1 {
    font-size: 28px;
    font-weight: 600;
    font-family: "Baloo Bhai 2", sans-serif;
    padding-left: 10px;
    opacity: 0.6;
  }
  p {
    text-align: center;
    font-size: 50px;
    font-weight: 600;
    font-family: "Baloo Bhai 2", sans-serif;
  }
  @media only screen and (max-width: 1200px) {
    flex: 1;
    width: 100%;
    padding: 0 10px;
  }
`;
