import LinkGenerateComponent from "../../constant/tables/linkGenerate/controlListTable";

const LinkGenerate = () => {
  return (
    <div>
      <LinkGenerateComponent />
    </div>
  );
};

export default LinkGenerate;
