import * as React from "react";
import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import SearchIcon from "@mui/icons-material/Search";

export default function TodoTable() {
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "flex", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel sx={{ fontSize: 14 }}>Search for user</FormLabel>
          <Input
            size="sm"
            placeholder="Search by name, country, or phone"
            startDecorator={<SearchIcon />}
            value={searchQuery}
            onChange={handleSearch}
          />
        </FormControl>
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "flex", sm: "flex" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: 48,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              >
                번호
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>이름</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                전화 번호
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>비자</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>국가</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>이유</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                담당자
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>날짜</th>
              <th
                style={{
                  padding: "12px 6px",
                  textAlign: "center",
                }}
              >
                보기
              </th>
            </tr>
          </thead>
          <tbody></tbody>
        </Table>
      </Sheet>
    </React.Fragment>
  );
}
