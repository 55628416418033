import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import {
  AdminList,
  AdminListApiResponse,
  Company,
  CompanyApiResponse,
  formatDate,
} from "../../../../types/data";
import SearchIcon from "@mui/icons-material/Search";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { DialogTitle, Modal, ModalDialog, Stack } from "@mui/joy";

import SvgIcon from "@mui/joy/SvgIcon";
import { styled } from "@mui/joy";
import { baseURL } from "../../../../../config";
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

export default function OrderTable() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [companyData, setCompanyData] = React.useState<Company[]>([]);
  const [filteredData, setFilteredData] = React.useState<Company[]>([]);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [cadData, setCadData] = React.useState<Company[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const [paginationData, setPaginationData] = React.useState<any>({
    after_filtering_count: 0,
    current_page: 1,
    next_page: null,
    page_count: 0,
  });

  const [page, setPage] = React.useState(paginationData.current_page);

  const handlePageChange = async (newPage: number) => {
    await fetchDocuments(newPage);
    setPage(newPage);
  };

  const fetchDocuments = async (page: number) => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        baseURL + "/user-data/welding?page=" + page.toString(),
        {
          method: "GET",
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: CompanyApiResponse = await response.json();
      setPaginationData({
        after_filtering_count: data.after_filtering_count,
        current_page: data.current_page,
        next_page: data.next_page,
        page_count: data.page_count,
      });
      setCompanyData(data.data);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    fetchDocuments(1);
  }, []);

  // React.useEffect(() => {
  //   fetchDocuments(1);
  // }, []);

  console.log("Cad Data:", companyData);
  // console.log("companyData", companyData);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
      const filtered = companyData.filter(
        (company) =>
          (company.reason.reason === "Education" &&
            company.education_type === "Autocad" &&
            company.name.toLowerCase().includes(query.toLowerCase())) ||
          company.country.toLowerCase().includes(query.toLowerCase()) ||
          company.phone.toLowerCase().includes(query.toLowerCase()) ||
          company.visa.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(companyData);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    const totalNumbers = 6;
    const sideNumbers = 1;

    if (paginationData.page_count <= totalNumbers) {
      for (let i = 1; i <= paginationData.page_count; i++) {
        pages.push(i);
      }
    } else {
      let startPage = Math.max(2, page - sideNumbers);
      let endPage = Math.min(paginationData.page_count - 1, page + sideNumbers);

      if (page <= sideNumbers + 2) {
        startPage = 2;
        endPage = totalNumbers - 1;
      } else if (page >= paginationData.page_count - sideNumbers - 1) {
        startPage = paginationData.page_count - totalNumbers + 2;
        endPage = paginationData.page_count - 1;
      }

      pages.push(1);
      if (startPage > 2) pages.push("...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < paginationData.page_count - 1) pages.push("...");
      pages.push(paginationData.page_count);
    }

    return pages;
  };
  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "flex", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel sx={{ fontSize: 14 }}>Search for user</FormLabel>
          <Input
            size="sm"
            placeholder="Search by name, country, or phone"
            startDecorator={<SearchIcon />}
            value={searchQuery}
            onChange={handleSearch}
          />
        </FormControl>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <FormLabel sx={{ fontSize: 14, fontWeight: 600 }}>
            무료 수업 학생:
          </FormLabel>
          <FormLabel sx={{ fontSize: 14, fontWeight: 600 }}>
            유료 수업 학생:
          </FormLabel>
        </div>
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "flex", sm: "flex" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: 48,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              >
                번호
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>이름</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                전화 번호
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>주소</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>국가</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>비자</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                수업 날짜
              </th>

              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                상세보기
              </th>
            </tr>
          </thead>
          <tbody>
            {companyData.map((company, index) => (
              <tr
                key={company._id}
                style={{
                  backgroundColor:
                    company.reason.education_salary_type === "Free"
                      ? "#cde8bf"
                      : "transparent",
                }}
              >
                <td style={{ textAlign: "center", width: 120 }}>
                  <Typography level="body-xs">{index + 1}</Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.name || "no name"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.phone || "no phone"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.address || "no address"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.country || "no country"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">{company.visa || "-"}</Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.reason.education_date || "-"}
                  </Typography>
                </td>

                <td style={{ textAlign: "center" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    {/* <Typography level="body-xs">
                      <Link
                        level="body-xs"
                        component="button"
                        onClick={() => setOpen(true)}
                      >
                        Upload
                      </Link>
                    </Typography> */}
                    <Modal open={open} onClose={() => setOpen(false)}>
                      <ModalDialog>
                        <DialogTitle
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          Upload Document
                        </DialogTitle>

                        <form
                          onSubmit={(
                            event: React.FormEvent<HTMLFormElement>
                          ) => {
                            event.preventDefault();
                            setOpen(false);
                          }}
                        >
                          <Stack spacing={2}>
                            <FormControl>
                              <Button
                                component="label"
                                role={undefined}
                                tabIndex={-1}
                                variant="outlined"
                                color="neutral"
                                startDecorator={
                                  <SvgIcon>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                      />
                                    </svg>
                                  </SvgIcon>
                                }
                              >
                                Upload a file
                                <VisuallyHiddenInput type="file" />
                              </Button>
                            </FormControl>
                            <Button type="submit">Submit</Button>
                          </Stack>
                        </form>
                      </ModalDialog>
                    </Modal>
                    <Link
                      to={`/education/welding-data/${company._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        level="body-xs"
                        color="primary"
                        fontSize={13}
                        sx={{ cursor: "pointer" }}
                      >
                        View
                      </Typography>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          onClick={() => handlePageChange(page - 1)}
          disabled={page <= 1}
        >
          Previous
        </Button>
        <Box sx={{ flex: 1 }} />
        {renderPageNumbers().map((pageNumber, index) => (
          <IconButton
            key={index}
            size="sm"
            variant={pageNumber === page ? "solid" : "outlined"}
            color="neutral"
            onClick={() =>
              typeof pageNumber === "number" && handlePageChange(pageNumber)
            }
            disabled={pageNumber === "..."}
          >
            {pageNumber}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          onClick={() => handlePageChange(page + 1)}
          disabled={page >= paginationData.page_count}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}
