/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";

import Table from "@mui/joy/Table";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";

import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Sheet from "@mui/joy/Sheet";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

import Divider from "@mui/joy/Divider";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/joy/DialogActions";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Stack from "@mui/joy/Stack";

import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

import Snackbar from "@mui/joy/Snackbar";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import { baseURL } from "../../../../config";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

interface Link {
  _id: string;
  name: string;
  open_count: number;
  reg_count: number;
  status: string;
  description: string;
}

interface LinkGenerateTableProps {
  getAll: any;
  setGetAll: any;
}

export default function LinkGenerateTable({
  getAll,
  setGetAll,
}: LinkGenerateTableProps) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [openE, setOpenE] = React.useState(false);
  const [openS, setOpenS] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [openSuccess, setSuccessOpen] = React.useState(false);

  const [linkData, setLinkData] = React.useState<Link[]>([]);
  const [paginationData, setPaginationData] = useState<any>({
    after_filtering_count: 0,
    current_page: 1,
    next_page: null,
    page_count: 0,
  });
  const [page, setPage] = useState(paginationData.current_page);
  const [selectedID, setSelectedID] = useState("");

  const handlePageChange = async (newPage: number) => {
    await fetchLink(newPage);
    setPage(newPage);
  };

  const fetchLink = async (page: number) => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        baseURL + "/sns-link/all?page=" + page.toString(),
        {
          method: "GET",
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setLinkData(data.data);
      setPaginationData({
        after_filtering_count: data.after_filtering_count,
        current_page: data.current_page,
        next_page: data.next_page,
        page_count: data.page_count,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    const totalNumbers = 6;
    const sideNumbers = 1;

    if (paginationData.page_count <= totalNumbers) {
      for (let i = 1; i <= paginationData.page_count; i++) {
        pages.push(i);
      }
    } else {
      let startPage = Math.max(2, page - sideNumbers);
      let endPage = Math.min(paginationData.page_count - 1, page + sideNumbers);

      if (page <= sideNumbers + 2) {
        startPage = 2;
        endPage = totalNumbers - 1;
      } else if (page >= paginationData.page_count - sideNumbers - 1) {
        startPage = paginationData.page_count - totalNumbers + 2;
        endPage = paginationData.page_count - 1;
      }

      pages.push(1);
      if (startPage > 2) pages.push("...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < paginationData.page_count - 1) pages.push("...");
      pages.push(paginationData.page_count);
    }

    return pages;
  };

  const copyToClipboard = async (id: string) => {
    try {
      await navigator.clipboard.writeText(
        `https://question.urconnection.co.kr/?source=${id}`
      );
      setSuccessOpen(true);
      setTimeout(() => {
        setSuccessOpen(false);
      }, 2500);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteLink = async () => {
    setOpen(false);
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(baseURL + "/sns-link/delete/" + selectedID, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      fetchLink(1);
    } catch (err) {
      console.log(err);
    }
  };

  const [formData, setFormData] = React.useState({
    name: "",
    description: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const token = localStorage.getItem("token");
    event.preventDefault();

    try {
      const response = await axios.put(
        baseURL + "/sns-link/update/" + selectedID,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.success) {
        setOpenE(false);
        setGetAll(true);
        setFormData({ name: "", description: "" });
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error?.msg);
    }
  };

  const updateStatus = async (event: React.FormEvent<HTMLFormElement>) => {
    const token = localStorage.getItem("token");
    event.preventDefault();

    try {
      const response = await axios.patch(
        baseURL + "/sns-link/status/" + selectedID + "/?status=" + status,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.success) {
        setOpenS(false);
        setGetAll(true);
        setStatus("");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error?.msg);
    }
  };

  React.useEffect(() => {
    fetchLink(1);
    if (getAll) {
      fetchLink(1);
      setGetAll(false);
    }
  }, [getAll]);

  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "flex", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel sx={{ fontSize: 14 }}>Search for order</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
          />
        </FormControl>
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "flex", sm: "flex" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{ width: 48, textAlign: "center", padding: "12px 6px" }}
              >
                번호
              </th>
              <th
                style={{
                  padding: "12px 6px",
                  textAlign: "center",
                  width: 250,
                }}
              >
                SNS
              </th>
              <th
                style={{
                  padding: "12px 6px",
                  textAlign: "center",
                  width: 180,
                }}
              >
                Status
              </th>
              <th
                style={{
                  padding: "12px 6px",
                  textAlign: "center",
                  width: 180,
                }}
              >
                Open
              </th>
              <th
                style={{
                  padding: "12px 6px",
                  textAlign: "center",
                  width: 180,
                }}
              >
                Reg
              </th>
              <th
                style={{
                  padding: "12px 6px",
                  textAlign: "center",
                  // width: "300px",
                }}
              >
                Description
              </th>

              <th
                style={{
                  padding: "12px 6px",
                  textAlign: "center",
                  width: 180,
                }}
              >
                설정
              </th>
            </tr>
          </thead>
          <tbody>
            {linkData.map((item, index) => (
              <tr key={item._id}>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {Number(index + 1) < 10
                      ? (page - 1).toString() + Number(index + 1)
                      : page + "0"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">{item.name}</Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {item.status}
                    <Button
                      variant="outlined"
                      color="warning"
                      sx={{ width: 40, marginLeft: "10px" }}
                      onClick={() => {
                        setOpenS(true);
                        setSelectedID(item._id);
                        setStatus(item.status);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </Button>
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">{item.open_count}</Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">{item.reg_count}</Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">{item.description}</Typography>
                </td>

                <td
                // style={{
                //   display: "flex",
                //   justifyContent: "end",
                //   marginTop: "4px",
                //   alignItems: "center",
                // }}
                >
                  <Button
                    variant="outlined"
                    sx={{ width: 40 }}
                    onClick={() => copyToClipboard(item._id)}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </Button>
                  <Button
                    variant="outlined"
                    color="warning"
                    sx={{ width: 40, marginLeft: "10px" }}
                    onClick={() => {
                      setOpenE(true);
                      setSelectedID(item._id);
                      setFormData({
                        name: item.name,
                        description: item.description,
                      });
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </Button>
                  <Button
                    variant="outlined"
                    color="danger"
                    sx={{ width: 40, marginLeft: "10px" }}
                    onClick={() => {
                      setOpen(true);
                      setSelectedID(item._id);
                    }}
                  >
                    <DeleteOutlineIcon fontSize="small" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>

      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          onClick={() => handlePageChange(page - 1)}
          disabled={page <= 1}
        >
          Previous
        </Button>
        <Box sx={{ flex: 1 }} />
        {renderPageNumbers().map((pageNumber, index) => (
          <IconButton
            key={index}
            size="sm"
            variant={pageNumber === page ? "solid" : "outlined"}
            color="neutral"
            onClick={() =>
              typeof pageNumber === "number" && handlePageChange(pageNumber)
            }
            disabled={pageNumber === "..."}
          >
            {pageNumber}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          onClick={() => handlePageChange(page + 1)}
          disabled={page >= paginationData.page_count}
        >
          Next
        </Button>
      </Box>
      <Snackbar
        variant="soft"
        color="success"
        open={openSuccess}
        onClose={() => setSuccessOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
      >
        Link copied successfully!
      </Snackbar>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>Are you sure you want to delete LINK?</DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={deleteLink}>
              Delete
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      <Modal open={openE} onClose={() => setOpenE(false)}>
        <ModalDialog>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Update SNS Link Generate
          </DialogTitle>

          <form onSubmit={updateSubmit}>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  autoFocus
                  required
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Input
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </FormControl>
              <Button type="submit">Submit</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>

      <Modal open={openS} onClose={() => setOpenS(false)}>
        <ModalDialog>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Update Status
          </DialogTitle>

          <form onSubmit={updateStatus}>
            <Stack spacing={2}>
              <FormControl>
                <Select
                  onChange={(e, value: any) => {
                    setStatus(value);
                  }}
                  value={status}
                >
                  <Option value="active">Active</Option>
                  <Option value="inactive">Inactive</Option>
                </Select>
              </FormControl>
              <Button type="submit">Submit</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
